// src/components/Navbar.js
import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Navbar, Nav, NavItem, NavbarBrand, NavbarToggler, Collapse, Button } from 'reactstrap';

const Navigation = ({ onNavClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Navbar dark expand="md" style={{ backgroundColor: '#03061a', padding: '1rem 2rem' }}>
      <NavbarBrand href='/'>
      <a className="navbar-brand" href="/"><img src={require('../assets/images/logoHyveCode.png')} className="navbar-logo" /></a>

      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
      <Nav className="ml-auto custom-nav" navbar>
        
        <NavItem>
        <div
              onClick={() => onNavClick('conocenos')}
              style={{ color: '#fff', marginRight: '20px', cursor: 'pointer', textDecoration: 'none' }}
            >
              CONOCENOS
            </div>
        </NavItem>
        <NavItem>
        <div
              onClick={() => onNavClick('servicios')}
              style={{ color: '#fff', marginRight: '20px', cursor: 'pointer', textDecoration: 'none' }}
            >
              NUESTROS SERVICIOS
            </div>
        </NavItem>
        <NavItem>
          <Link to="/proyectos" style={{ color: '#fff', marginRight: '20px', textDecoration: 'none' }}>PROYECTOS INDEPENDIENTES</Link>
        </NavItem>
        <NavItem>
        <Link to="/blogsection" style={{ color: '#fff', marginRight: '20px', textDecoration: 'none' }}>BLOG</Link>
        </NavItem>
        <NavItem>
              <Button
              size='sm'
                color="primary"
                onClick={() => onNavClick('contacto')}
                style={{
                  background: 'linear-gradient(130deg, rgba(101,101,208,1) 0%, rgba(96,162,230,1) 47%, rgba(205,170,108,1) 100%)',
                  borderRadius: '20px',
                  padding: '10px 20px',
                  color: '#fff',
                  fontWeight: 'bold'
                }}
              >
                CONTACTO
              </Button>
            </NavItem>
      </Nav>
      </Collapse>
      
    </Navbar>
  );
};

export default Navigation;
