import React, { useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Col,
  Row,
  Container
} from 'reactstrap';

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formValues.name) formErrors.name = 'El nombre es requerido';
    if (!formValues.email) {
      formErrors.email = 'El correo es requerido';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      formErrors.email = 'El correo no es válido';
    }
    if (!formValues.phone) {
      formErrors.phone = 'El número de teléfono es requerido';
    } else if (!/^\d{10}$/.test(formValues.phone)) {
      formErrors.phone = 'El número de teléfono debe tener 10 dígitos';
    }
    if (!formValues.message) formErrors.message = 'El mensaje es requerido';
    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      console.log('Form Submitted', formValues);
      // Aquí puedes manejar el envío de datos
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <Container className='contact-container' style={{ marginTop: '2em', marginBottom: '5em'}}>
      <h1 className="titles-home">Contactanos</h1>
     
      <Row style={{alignItems:"center", justifyContent: 'center'}}>
        <Col xs='12' md='10'>
        <div className='form-container'>
        <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">Nombre</Label>
          <Input
            type="text"
            name="name"
            id="name"
            value={formValues.name}
            onChange={handleChange}
            invalid={!!errors.name}
            placeholder="Tu nombre"
          />
          <FormFeedback>{errors.name}</FormFeedback>
        </FormGroup>

       

        <FormGroup>
          <Label for="phone">Teléfono</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            value={formValues.phone}
            onChange={handleChange}
            invalid={!!errors.phone}
            placeholder="Tu numero telefonico"
          />
          <FormFeedback>{errors.phone}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="email">Correo Electronico</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={formValues.email}
            onChange={handleChange}
            invalid={!!errors.email}
            placeholder="tu correo"
          />
          <FormFeedback>{errors.email}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="message">Comentario</Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            value={formValues.message}
            onChange={handleChange}
            invalid={!!errors.message}
            placeholder="Comentario"
          />
          <FormFeedback>{errors.message}</FormFeedback>
        </FormGroup>
        <div className='text-center'>
      <Button
              size='sm'
                style={{
                  background: 'linear-gradient(130deg, rgba(101,101,208,1) 0%, rgba(96,162,230,1) 47%, rgba(205,170,108,1) 100%)',
                  borderRadius: '20px',
                  padding: '10px 20px',
                  color: '#fff',
                  fontWeight: 'bold'
                }}
                type='submit'
              >
                ENVIAR
              </Button>

        </div>

        
      </Form>
      
      </div>
        </Col>
       
      </Row>
        
     
      
      
    </Container>
  );
};

export default ContactForm;

