import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import ContactForm from '../components/ContacForm'

const Proyectos = () => {
  return (
    <div className='proyects'>
      <Container>
      <Row className='justify-content-center text-center mt-5'>
        <Col sm='12' md='10'>
        <img src={require('../assets/images/Bizzion.png')} alt="bizzion" style={{width: '50%'}}/>
        <h1 style={{color: 'white', marginTop:'1rem'}}>NUESTRO PROYECTO INDEPENDIENTE</h1>
        </Col>
      </Row>
      
      <Row className='justify-content-center text-center mt-5'>
        <Col md='10'>
        <img src={require('../assets/images/Bizion1Full.png')} alt="bizion full" style={{width: '100%'}}/>
        </Col>
      </Row>
      <Row className='justify-content-center text-center mt-5'>
        <Col md='10'>
        <img src={require('../assets/images/Bizion2Full.png')} alt="bizion full" style={{width: '100%'}}/>
        </Col>
      </Row>
      <Row className='justify-content-center text-center mt-5'>
        <Col md='10'>
        <img src={require('../assets/images/Bizion3Full.png')} alt="bizion full" style={{width: '100%'}}/>
        </Col>
      </Row>
      <Row className='justify-content-center text-center mt-5'>
        <Col md='10'>
        <img src={require('../assets/images/Bizion4Full.png')} alt="bizion full" style={{width: '100%'}}/>
        </Col>
      </Row>
      <Row className='justify-content-center text-center'>
        <Col md='10'>
        <img src={require('../assets/images/Bizion5Full.png')} alt="bizion full" style={{width: '100%'}}/>
        </Col>
      </Row>
      <Row className='justify-content-center text-center mt-5'>
        <Col sm='12' md='10'>
        
        <h1 style={{color: 'white', margin:'2rem 0rem', fontSize: '64px'}}>ESPERA NUESTROS PROXIMOS PROYECTOS!</h1>
        </Col>
      </Row>
      
    </Container>
    <ContactForm/>

    

    </div>
    

  )
}

export default Proyectos