import React from 'react';
import { BrowserRouter as Router,useNavigate, useLocation } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import AppRouter from './routes/AppRouter';
import Footer from './components/Footer';
import NavBar from './components/Navbar';

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (section) => {
    if (location.pathname === '/') {
      // Si ya está en Home, simplemente desplazarse a la sección
      scroller.scrollTo(section, {
        smooth: true,
        duration: 500,
      });
    } else {
      // Si no está en Home, redirigir primero a Home y luego desplazarse
      navigate('/');
      setTimeout(() => {
        scroller.scrollTo(section, {
          smooth: true,
          duration: 500,
        });
      }, 100); // Agregar un pequeño retraso para asegurarse de que la página se haya cargado
    }
  };
  return (
    
       <div className="app-wrapper">
       <NavBar onNavClick={handleNavClick}/>
      <AppRouter onNavClick={handleNavClick}/>
      <Footer onContactClick={() => handleNavClick('contacto')} />
      </div>
    
  );
}

export default App;
