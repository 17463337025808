// BlogSection.js
import React, { useState } from 'react';
import BlogCard from '../components/BlogCard';
import { Container } from 'reactstrap';

const BlogSection = () => {
  // Lista de posts de ejemplo
  const posts = [
    {
      id: 1,
      title: 'El rol de la ciberseguridad en tu empresa',
      date: '6 septiembre 2024',
      image: require("../assets/images/Post1.png"),
      description:"En un mundo cada vez más digital, la ciberseguridad se ha convertido en un pilar fundamental para la protección de los activos y la continuidad operativa de cualquier empresa, sin importar su tamaño o sector.",
      link: '/blog/1',
    },
    {
      id: 2,
      title: 'La Revolución de la Computación Cuántica en las Empresas sobre tecnología',
      date: '4 septiembre 2024',
      image: require("../assets/images/Post2.png"),
      description: "La computación cuántica va a permitir a las empresas optimizar mejor sus estrategias de inversión, el cifrado o descubrir productos. Son muchos gigantes tecnológicos como Microsoft, Honeywell o IBM los que están invirtiendo en esta carrera de fondo para ofrecer el próximo avance cuántico.",
      link: '/blog/2',
    },
    {
      id: 3,
      title: 'La Revolución de la Inteligencia Artificial Generativa en los Negocios 2024',
      date: '2 septiembre 2024',
      image: require("../assets/images/Post3.png"),
      description: "La inteligencia artificial generativa (IA generativa) está revolucionando el mundo empresarial, transformando la forma en que se realizan negocios, se crean productos y se interactúan con clientes.",
      link: '/blog/3',
    },
    
    // Más posts...
  ];

  // Estado para la paginación
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  // Cálculo de los índices de los posts actuales
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Número total de páginas
  const totalPages = Math.ceil(posts.length / postsPerPage);

  // Función para cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
    
    <Container  >
    <div className='hero-section'>
    <h2 className="titles-home">Blogs</h2>
    <div className="blog-section">
        {currentPosts.map((post) => (
          <BlogCard key={post.id} post={post} />
        ))}
      </div>

      {/* Paginación */}

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
      </div>
    </Container>

    
    
    </>
  );
};

export default BlogSection;
