import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = ({ onContactClick }) => {
  return (
    <footer style={{ backgroundColor: '#333', color: '#fff', padding: '2rem 0', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}>
      <Container>
        <Row >
          {/* Sección izquierda */}
          <Col xs="12" md="4" className="mb-4 mb-md-0 text-md-left">
            <h5>HYVE CODE TEAM</h5>
            <ul className="list-unstyled">
              <li><a href="#about" style={{ color: '#fff', textDecoration: 'none' }}>Acerca de nosotros</a></li>
              <li><a href="#contact" onClick={(e) => {e.preventDefault();onContactClick()} } style={{ color: '#fff', textDecoration: 'none' }}>Contacto</a></li>
              <li><a href="#privacy" style={{ color: '#fff', textDecoration: 'none' }}>Aviso de privacidad</a></li>
            </ul>
          </Col>

          {/* Sección del medio */}
          <Col xs="12" md="4" className="d-flex flex-column justify-content-between align-items-center text-center mb-4 mb-md-0">
            <img src={require('../assets/images/logoHyveCode.png')} alt="Hyve Code Logo" style={{ width: '150px', marginBottom: '1rem' }} />
            <p>www.hyvecode.com.mx</p>
          </Col>

          {/* Sección derecha */}
          <Col xs="12" md="4" className="d-flex flex-column justify-content-between text-md-end">
            <div className="social-icons">
              <a href="https://www.facebook.com/profile.php?id=61564695069605&mibextid=ZbWKwL" target='_blank' style={{ color: '#fff', marginRight: '1rem' }}><img src={require('../assets/images/Facebook.png')} alt='facebook'/></a>
              <a href="https://www.instagram.com/hyvecode?igsh=MTFucWdra3N4dXA4ZA==" target='_blank' style={{ color: '#fff' }}><img src={require('../assets/images/Instagram.png')} alt='facebook'/></a>
            </div>
            <p className="mt-3">©2024 Hyve Code. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;