import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ContactForm from '../components/ContacForm'

const BlogPost = ({ posts }) => {
  const { id } = useParams();
  const post = posts.find(p => p.id === parseInt(id, 10));

  if (!post) {
    return <div>Post no encontrado</div>;
  }

  return (
    <div className='post-header'>
      <Container>
      
      <Row className='justify-content-center'>
      
        <Col sm="12" md='10' className='text-center'>
        <h1 className='title-posts'>{post.title}</h1>
        <img src={post.image} alt={post.title} className='img-post' />

        </Col>
      </Row>
      <Row className='justify-content-center mt-5'>
        <Col sm="12" md='10' className='text-content'>
        <p>{post.content1}</p>

        </Col>
      </Row>
      <Row className='justify-content-center mt-5'>
        <Col sm="12" md='10' className='text-content'>
        <h2>{post.subtitle1}</h2>
        <p>{post.content2}</p>
        </Col>
      </Row>
      <Row className='justify-content-center mt-3'>
        <Col sm="12" md='10' className='text-content'>
        <h2>{post.subtitle2}</h2>
        <p>{post.content3}</p>
        </Col>
      </Row>
      
      
    </Container>
    <ContactForm/>
    </div>
    
  );
};

export default BlogPost;