import React from 'react';
import { Route, Routes, } from 'react-router-dom';
import Home from '../pages/Home';
import Conocenos from '../pages/Conocenos';
import Servicios from '../pages/Servicios';
import BlogSection from '../pages/BlogSection';
import BlogPost from '../pages/BlogPost';
import Proyectos from '../pages/Proyectos'
import { posts } from '../components/data/Posts';
import ContactForm from '../components/ContacForm';

function AppRouter({ onNavClick }) {
  return (
    <Routes>
      <Route path="/" element={<Home onContactClick={() => onNavClick('contacto')} />} />
      <Route path="/conocenos" element={<Conocenos />} />
      <Route path='/servicios' element={<Servicios/>}/>
      <Route path='/blogsection' element={<BlogSection/>}/>
      <Route path='/contact' element={<ContactForm/>}/>
      
      <Route path='/proyectos' element={<Proyectos/>}/>
      <Route path="/blog/:id" element={<BlogPost posts={posts} />} />
    </Routes>
  );
}

export default AppRouter;