import React from 'react'
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'
import BlogSlider from '../components/BlogSlider'
import { posts } from '../components/data/Posts'
import ContactForm from '../components/ContacForm'

const Home = () => {
  const values = [
    { title: "Innovación", description: "Exploramos y aplicamos nuevas tecnologías." },
    { title: "Coolaboración", description: "Cada miembro aporta su talento para lograr objetivos." },
    { title: "Compartir Conocimiento", description: "Compartimos nuestros descubrimientos y experiencias con nuestra comunidad." },
    { title: "Compromiso con la Calidad", description: "Nos esforzamos por entregar productos y servicios de la más alta calidad." },
    { title: "Pasión por la Tecnología", description: "Nuestra pasión nos motiva a desarrollar y crear soluciones tecnológicas." },
    { title: "Adaptabilidad y Resiliencia", description: "Enfrentamos desafíos con flexibilidad y madurez, aprendiendo y evolucionando constantemente." },
  ];
  const industrias = [
    {
      image: require('../assets/icons/Salud.png'),
      title: 'Salud'
    },
    {
      image: require('../assets/icons/Educacion.png'),
      title: 'Educación'
    },
    {
      image: require('../assets/icons/CentrosComerciales.png'),
      title: 'Centros comerciales'
    },
    {
      image: require('../assets/icons/Gobierno.png'),
      title: 'Instituciones Gubernamentales'
    },
    {
      image: require('../assets/icons/Industrias.png'),
      title: 'Instalaciones industriales'
    },
    {
      image: require('../assets/icons/Pymes.png'),
      title: 'PYMES'
    },
    {
      image: require('../assets/icons/Privada.png'),
      title: 'Privado'
    },
  ]
  const slides = [
    {
      id:1,
      title:'El rol de la ciberseguridad en tu empresa',
      description:'En un mundo cada vez más digital, la ciberseguridad se ha convertido en un pilar fundamental...',
      image: 'https://via.placeholder.com/300x200',
      content:'contenido del post1....'
    },
    {
      id:2,
      title:'El rol de la ciberseguridad en tu empresa',
      description:'En un mundo cada vez más digital, la ciberseguridad se ha convertido en un pilar fundamental...',
      image: 'https://via.placeholder.com/300x200',
      content:'contenido del post1....'
    },
    {
      id:3,
      title:'El rol de la ciberseguridad en tu empresa',
      description:'En un mundo cada vez más digital, la ciberseguridad se ha convertido en un pilar fundamental...',
      image: 'https://via.placeholder.com/300x200',
      content:'contenido del post1....'
    }
  ]
  
  const services = [
    {
      title: 'Desarrollo Web',
      technologies: [
        { name: 'React', icon:  require('../assets/icons/react-icon.png') },
        { name: 'Javascript', icon:  require('../assets/icons/javascript-icon.png') },
        { name: 'HTML', icon:  require('../assets/icons/html-icon.png') },
        { name: 'CSS', icon:  require('../assets/icons/css-icon.png') }
      ]
    },
    {
      title: 'Desarrollo de Software Personalizado',
      technologies: [
        { name: 'Node JS', icon: require('../assets/icons/nodejs-icon.png') },
        { name: 'Python', icon: require('../assets/icons/python-icon.png') },
        { name: 'Django', icon: require('../assets/icons/django-icon.png') }
      ]
    },
    {
      title: 'Inteligencia Artificial',
      technologies: [
        { name: 'Google Cloud IA', icon: require('../assets/icons/cloud-icon.png') }
      ]
    },
    {
      title: 'Desarrollo Móvil',
      technologies: [
        { name: 'Android', icon: require('../assets/icons/android-icon.png') },
        { name: 'React Native', icon: require('../assets/icons/reactnative-icon.png') },
        { name: 'Swift', icon: require('../assets/icons/swift-icon.png')}
      ]
    },
    // Agrega más servicios según sea necesario...
  ];

  return (<>
 
  <div className='header-hyve text-center' style={{display:'flex', flexDirection: 'column',justifyContent: "center", alignItems:'center'}}>
    <img src={require('../assets/images/logoHyveCode.png')} alt="hyve" width={'80%'}/>
    <h1 className='text-header'>"Transformando ideas en soluciones tecnologicas"</h1>
  </div>
  <h1 id='servicios' className="titles-home">Servicios</h1>
  <div  className='fondo-services'>
  <Container>
  
  <div className="services-container">
      {services.map((service, index) => (
        <div key={index} className="service-card">
          <h5 className="card-title-service">{service.title}</h5>
          <ul className="technology-list">
            {service.technologies.map((tech, idx) => (
              <li key={idx} className="technology-item">
                <img src={tech.icon} alt={tech.name} className="tech-icon" />
                <span>{tech.name}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>

  </Container>
  
  </div>
  <Container style={{padding: "2em 2em"}} id='conocenos'>
  <Row className='mt-5 justify-content-center' >   
      <Col sm={"12"} md={"7"} >
      <h1 style={{color: 'white'}}>¿Quiénes somos?</h1>
      <p style={{color: 'white', textAlign:"justify"}}>Hyve Code nació de la visión compartida de un equipo de desarrolladores expertos en tecnología, comprometidos con transformar el mercado a través de soluciones innovadoras. Desde su creación, nuestro enfoque ha sido claro: no solo desarrollar aplicaciones de alta calidad, sino también impulsar la innovación tecnológica y compartir conocimientos especializados, con el objetivo de generar un impacto positivo y duradero en el sector.</p>
      </Col>
      <Col sm={"12"} md={"4"} className='text-center'> 
      <img src={require('../assets/images/ServiceImg.png')} alt="services" />
      </Col>
    </Row>

  </Container>
  <h1 className='titles-home'>Industrias a las que servimos</h1>
  <div className='fondo-industrias'>
  <Container style={{padding: "2em 2em"}}>
    
    
    <Row className='justify-content-center'>
      <Col sm="12" md="10" >
      <div className="industrias-container">
      {industrias.map((service, index) => (
        <div key={index} className="industrias-card">
          <img src={service.image} alt={service.title} className='industrias-image'/>
          <h5 className="card-title-industrias">{service.title}</h5>
          
        </div>
      ))}
    </div>
      </Col>
    
    </Row>
    <Row className='justify-content-center' style={{marginBottom: '4em'}}>
      
      <Col xs='12' md='10'>
      <h1 className='titles-home'>Valores</h1>
      <p style={{color: 'white', textAlign:"justify"}}>Somos una empresa innovadora y dinámica, reflejamos nuestro compromiso con la tecnología de vanguardia; comunicamos de manera colaborativa y cercana, fomentando el intercambio de ideas y la participación. Contamos con una ideologia en donde para nosotros es inspirador motivar a nuestra comunidad a aprender y crecer.</p>
      </Col>
    </Row>
    <Row className='justify-content-center '>
      <Col md='10'>
      <Row>
        <Col sm='12' md='4'  style={{padding: '0px'}}> 
        <div className='section-normal'>
        <h3>{values[0].title}</h3>
        <p>{values[0].description}</p>
        </div>
          

        </Col>
        <Col sm='12' md='4'  style={{padding: '0px'}}>
        <div className='section-color'>
        <h3>{values[1].title}</h3>
        <p>{values[1].description}</p>
        </div>
        
        </Col>
        <Col sm='12' md='4'  style={{padding: '0px'}}>
        <div className='section-normal'>
        <h3>{values[2].title}</h3>
        <p>{values[2].description}</p>

        </div>
        
        </Col>
      </Row>
      </Col>
    </Row>
    <Row className='justify-content-center '>
      <Col md='10'>
      <Row>
        <Col sm='12' md='4'  style={{padding: '0px'}}> 
        <div className='section-color'>
        <h3>{values[3].title}</h3>
        <p>{values[3].description}</p>
        </div>
          

        </Col>
        <Col sm='12' md='4' style={{padding: '0px'}} >
        <div className='section-normal'>
        <h3>{values[4].title}</h3>
        <p>{values[4].description}</p>
        </div>
        
        </Col>
        <Col sm='12' md='4' style={{padding: '0px'}}>
        <div className='section-color'>
        <h3>{values[5].title}</h3>
        <p>{values[5].description}</p>

        </div>
        
        </Col>
      </Row>
      </Col>
    </Row>
    <Row className='justify-content-center' style={{marginTop:"3.5em"}}>
      <Col md='10'>
        <Row>
          <Col sm='12' md='8' style={{color: 'white'}}> 
          <h3>Misión</h3>
          <p style={{textAlign: 'justify'}}>
          Crear soluciones tecnológicas innovadoras que optimicen procesos, mejoren la eficiencia y potencien el crecimiento, adaptándonos al mercado y promoviendo la innovación.
          </p>
          <h3>Visión</h3>
          <p style={{textAlign: 'justify'}}>
          Ser líderes en innovación tecnológica, transformando negocios con nuestras aplicaciones.
          </p>
          </Col>
          <Col sm='12' md='4' className='image-content'> 
          <img src={require('../assets/images/MisionHyve.png')} alt="mision"  />
          </Col>
        </Row>
      </Col>
    </Row>
    
  </Container>
  </div>

  
  


  <Container id='blogs'>
    <BlogSlider posts={posts}/>
  </Container>
  <div id='contacto'><ContactForm/></div>
  

  
  </>
    
  )
}

export default Home